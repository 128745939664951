import React, { useEffect, useState } from "react";
import { Code } from "./code";

import "./snippet.scss";

interface IProps {
  rawUrl: string;
  link: string;
  body?: any;
}

export const Snippet = ({ link, rawUrl, body }: IProps) => {
  const [code, setCode] = useState("");
  useEffect(() => {
    fetch(rawUrl)
      .then((response) => response.text())
      .then((data) => setCode(data));
  });
  return <Code code={code} language="javascript" link={link} />;
};
