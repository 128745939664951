import React from "react";
import { FaGithub } from "react-icons/fa";

import github from "../../assets/fa/github.svg";
import "./link-header.scss";

interface IProps {
  link: string;
}

export const LinkHeader = ({ link }: IProps) => {
  return (
    <a href={link} target="_blank" title="View on GitHub" rel="noopener">
      <img className="link-header" alt="GitHub" src={github} />
      {/* <FaGithub className="link-header" /> */}
    </a>
  );
};
