import React, { useEffect } from "react";

import Prism from "prismjs";
// import "prismjs/themes/prism-tomorrow.css";
import "./prism-theme.scss";

import { Spinner } from "../spinner";

import "./code.scss";
import { LinkHeader } from "./link-header";

interface IProps {
  code: string;
  language: string;
  link?: string;
}

export const Code = ({ code, language, link }: IProps) => {
  useEffect(() => {
    Prism.highlightAll();
  });
  return (
    <div className="code--wrapper">
      {link && <LinkHeader link={link} />}
      {(code && (
        <div className="Code">
          <pre>
            <code className={`language-${language}`}>{code}</code>
          </pre>
        </div>
      )) || (
        <div className="code--spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
};
