import React from "react";
import VimeoEmbed from "@u-wave/react-vimeo";
import BlockContent from "@sanity/block-content-to-react";
import { buildFileUrl } from "@sanity/asset-utils";
import { dataset, fileUrlFor, projectId, urlFor } from "../../utils/utils";

import "./video.scss";
import cn from "classnames";

interface IProps {
  title?: string;
  asset: {
    _ref: string;
  };
  file: {
    asset: {
      _ref: string;
    };
  };
  image: {
    asset: {
      _ref: string;
    };
  };
  fullSize: boolean;
  noShadow: boolean;
  //   url: string;
  //   body?: any;
}

export const Video = (props: IProps) => {
  // console.log("VIDEOOO", props);
  // const idElements = props.asset._ref.split("-");
  const idElements = props.file?.asset._ref.split("-") || ["", ""];
  const url = `https://cdn.sanity.io/files/${projectId}/${dataset}/${idElements[1]}.${idElements[2]}`;
  return (
    <div>
      <div
        className={cn("video--container", {
          "video--container--full": props.fullSize,
          "video--no-shadow": props.noShadow === true,
        })}
      >
        <video src={url} muted autoPlay loop playsInline />
        <img
          src={(props.image && urlFor(props.image).url()) || ""}
          loading="lazy"
          alt={props.title}
        />
      </div>
      {/* <div className="vimeo--text">
        {body && <BlockContent blocks={body} />}
      </div> */}
    </div>
  );
};
