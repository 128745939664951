import React from "react";
import getYouTubeId from "get-youtube-id";
import YouTubeEmbed from "react-youtube";
import BlockContent from "@sanity/block-content-to-react";

import "./youtube.scss";

interface IProps {
  url: string;
  body?: any;
}

export const YouTube = ({ url, body }: IProps) => {
  const id = getYouTubeId(url);
  return (
    <div>
      <div className="youtube--container">
        <YouTubeEmbed
          videoId={id || ""}
          opts={{
            playerVars: { rel: 0 },
          }}
        />
      </div>
      <div className="youtube--text">
        {body && <BlockContent blocks={body} />}
      </div>
    </div>
  );
};
