import React from "react";

import BlockContent from "@sanity/block-content-to-react";

import { YouTube } from "./youtube";
import { Vimeo } from "./vimeo";
import { Video } from "./video";
import { ImageLink } from "./image-link";
import { ImageHeader } from "./image-header";
import { Quote } from "./quote";
import { Table } from "./table";
import { Divider } from "./divider";
import { IFrame } from "./iframe";
import { Code } from "./code";

import "./super-block-content.scss";
import { Snippet } from "./snippet";
import { urlFor } from "../../utils/utils";

import { getImageDimensions } from "@sanity/asset-utils";
import { Nest } from "./nest";
import { Grid } from "./grid";

interface IProps {
  blocks: any;
  projectId: any;
  dataset: any;
  className: any;
}

const serializers = {
  types: {
    nest: (props: any) => <Nest {...props.node} />,
    grid: (props: any) => <Grid {...props.node} />,
    youtube: (props: any) => <YouTube {...props.node} />,
    vimeo: (props: any) => <Vimeo {...props.node} />,
    video: (props: any) => <Video {...props.node} />,
    imageLink: (props: any) => <ImageLink {...props.node} />,
    imageHeader: (props: any) => <ImageHeader {...props.node} />,
    quote: (props: any) => <Quote {...props.node} />,
    table: (props: any) => <Table {...props.node} />,
    divider: (props: any) => <Divider {...props.node} />,
    iframe: (props: any) => <IFrame {...props.node} />,
    code: (props: any) => <Code {...props.node} />,
    snippet: (props: any) => <Snippet {...props.node} />,
    photo: (props: any) => {
      const maxWidth = 920;
      let { width, height, aspectRatio } = getImageDimensions(
        props.node.mainImage.asset
      );
      const resizeFactor = maxWidth / width;
      return (
        <figure>
          <img
            className="photo-item"
            width={maxWidth}
            height={height * resizeFactor}
            // style={{ aspectRatio: aspectRatio.toString() }}
            src={urlFor(props.node.mainImage.asset)
              .width(maxWidth)
              .format("webp")
              .url()}
            // srcSet={`${urlFor(props.node.mainImage.asset)
            //   .width(maxWidth)
            //   .format("webp")
            //   .url()} 920w,
            //   ${urlFor(props.node.mainImage.asset)
            //     .width(620)
            //     .format("webp")
            //     .url()} 620w,
            //   ${urlFor(props.node.mainImage.asset)
            //     .width(320)
            //     .format("webp")
            //     .url()} 320w,
            //   `}
            alt={props.node.title}
          />
        </figure>
      );
    },
  },
  marks: {
    link: (props: any) => {
      let { blank, href } = props.mark;
      const start = "https://hyperbrew.co";
      const isLocal = href.indexOf(start) === 0;
      isLocal ? (href = href.replace(start, "")) : 0;
      return isLocal ? (
        <a href={href}>{props.children}</a>
      ) : (
        <a
          href={href}
          target={blank ? `_blank` : ""}
          rel={isLocal ? "" : "noopener"}
        >
          {props.children}
        </a>
      );
    },
  },
};

export const SuperBlockContent = ({
  blocks,
  projectId,
  dataset,
  className,
}: IProps) => (
  <BlockContent
    blocks={blocks}
    projectId={projectId}
    dataset={dataset}
    className={className || ""}
    serializers={serializers}
  />
);
