import React from "react";
import "./bottom-wave.scss";

interface Props {
  type: "wave" | "dip";
}

export const BottomWave = ({ type }: Props) => {
  return (
    <div className="bottom-wave">
      <svg
        fill="#ffffff"
        className="top-wave--svg"
        viewBox="0 80 500 128.9"
        preserveAspectRatio="xMinYMin meet"
      >
        {type === "wave" && (
          <path d="M0,100c176.8,37.2,339.4-32.1,500,0V0H0V100z" />
        )}
        {type === "dip" && <path d="M0,100c187.9,9,338.6,12,500,0V0H0V100z" />}
      </svg>
    </div>
  );
};
