import React from "react";
import VimeoEmbed from "@u-wave/react-vimeo";
import BlockContent from "@sanity/block-content-to-react";

import "./vimeo.scss";

interface IProps {
  url: string;
  body?: any;
}

export const Vimeo = ({ url, body }: IProps) => {
  return (
    <div>
      <div className="vimeo--container">
        <VimeoEmbed
          video={url}
          showByline={false}
          showPortrait={false}
          showTitle={false}
          dnt={false}
        />
      </div>
      <div className="vimeo--text">
        {body && <BlockContent blocks={body} />}
      </div>
    </div>
  );
};
