import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { urlFor } from "../../utils/utils";
import "./image-link.scss";
import { getImageDimensions } from "@sanity/asset-utils";

interface IProps {
  url: string;
  mainImage: any;
  body?: any;
}

export const ImageLink = ({ mainImage, url, body }: IProps) => {
  const maxWidth = 900;
  let { width, height, aspectRatio } = getImageDimensions(mainImage.asset);
  const resizeFactor = maxWidth / width;
  const start = "https://hyperbrew.co";
  const isLocal = url.indexOf(start) === 0;
  isLocal ? (url = url.replace(start, "")) : 0;
  const aProps = isLocal ? {} : { target: "_blank", rel: "noopener" };
  // console.log(url, " :: ", aProps);
  return (
    <div>
      <div className="image-link--container">
        <a href={url} {...aProps}>
          <img
            width={width}
            height={height}
            style={{ aspectRatio: `${width} / ${height}` }}
            src={urlFor(mainImage).width(900).format("webp").url()}
            alt={url}
          />
          <div className="image-link--text">
            {body && <BlockContent blocks={body} />}
          </div>
        </a>
      </div>
    </div>
  );
};
