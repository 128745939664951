import React from "react";
import { SuperBlockContent } from "./super-block-content";

import "./grid.scss";

interface Props {
  body?: any[];
}

export const Grid = ({ body }: Props) => {
  return (
    <SuperBlockContent
      projectId={"g3so7nt7"}
      dataset={"production"}
      className="block-body template--body grid--container"
      blocks={body}
    />
  );
};
