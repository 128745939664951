import React from "react";
import "./table.scss";
import cn from "classnames";

interface TableCell {
  any: string;
}

interface TableRow {
  cells: TableCell[];
}

export const Table = (props: any) => {
  return (
    <div className="table--wrapper">
      <table className="table--container">
        <tbody>
          {props.rows.map((row: TableRow, i: number) => (
            <tr key={i}>
              {row.cells.map((cell, j) => (
                <td
                  key={j}
                  className={cn({
                    "table--index": j === 0 && i === 0,
                    "table--key": j === 0 && i !== 0,
                    "table--header": i === 0 && j !== 0,
                    "table--content": i !== 0 && j !== 0,
                  })}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
