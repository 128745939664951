import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { urlFor } from "../../utils/utils";
import "./quote.scss";
import { FaStar } from "react-icons/fa";
import star from "../../assets/fa/star.svg";

interface IProps {
  name: string;
  title: string;
  url: string;
  mainImage: any;
  body?: any;
}

export const Quote = ({ mainImage, url, body, name, title }: IProps) => {
  return (
    <div className="quote--container">
      <div className="quote--profile">
        <div className="quote--image--wrapper">
          <img
            className="quote--image"
            src={mainImage && urlFor(mainImage).format("webp").url()}
            alt={`${name} - ${title}`}
          />
        </div>
        <div className="quote--nametitle">
          <div className="quote--name">{name}</div>
          <div className="quote--title">{title}</div>
          <div className="quote--stars">
            <img src={star} alt="star" className="quote--stars--star" />
            <img src={star} alt="star" className="quote--stars--star" />
            <img src={star} alt="star" className="quote--stars--star" />
            <img src={star} alt="star" className="quote--stars--star" />
            <img src={star} alt="star" className="quote--stars--star" />
          </div>
        </div>
      </div>
      <div className="quote--text">
        {body && <BlockContent blocks={body} />}
      </div>
    </div>
  );
};
