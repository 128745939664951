import React from "react";
import { SuperBlockContent } from "./super-block-content";

import "./nest.scss";
import { TopWave } from "../top-wave";
import { BottomWave } from "../bottom-wave";
import cn from "classnames";

interface Props {
  bodyA?: any;
  bodyB?: any;
  style: "wave" | "dip";
  background: boolean;
  topBorder: boolean;
  bottomBorder: boolean;
  flip: boolean;
  color: "primary" | "secondary" | "third";
}

export const Nest = ({
  bodyA,
  bodyB,
  background,
  style,
  color,
  topBorder,
  bottomBorder,
  flip,
}: Props) => {
  if (!bodyB || !bodyA) {
    // console.log("ONE OF THE THINGS ARE MISSING");
  }
  return (
    <div
      className={cn(
        "nest--outer",
        { "nest--primary": background === true && color === "primary" },
        { "nest--secondary": background === true && color === "secondary" },
        { "nest--third": background === true && color === "third" },
        { "nest--blank": background === false }
      )}
    >
      {background && topBorder && <TopWave type={style || "dip"} />}
      <div
        className={cn("nest--body", {
          "nest--single": !bodyB || !bodyA,
          "nest--flip": bodyB && bodyA && flip,
        })}
      >
        {bodyA && (
          <SuperBlockContent
            projectId={"g3so7nt7"}
            dataset={"production"}
            className="block-body template--body"
            blocks={bodyA}
          />
        )}
        {bodyB && (
          <SuperBlockContent
            projectId={"g3so7nt7"}
            dataset={"production"}
            className="block-body template--body"
            blocks={bodyB}
          />
        )}
      </div>
      {topBorder && <div className="planet--moon-a"></div>}
      {bottomBorder && <div className="planet--moon-b"></div>}
      {/* {!background && topBorder && (
        <div className="planet--ring-a">
          <div className="planet--circle-a"></div>
          <div className="planet--circle-b"></div>
        </div>
      )}
      {!background && bottomBorder && <div className="planet--ring-b"></div>} */}
      {background && bottomBorder && <BottomWave type={style || "dip"} />}
    </div>
  );
};
