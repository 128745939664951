import React from "react";

import "./iframe.scss";
import { LinkHeader } from "./link-header";

interface IProps {
  url: string;
  link: string;
  title: string;
  body: any;
}

export const IFrame = ({ url, link, body, title }: IProps) => {
  return (
    <div className="custom--iframe--wrapper">
      {link && <LinkHeader link={link} />}
      <iframe
        className="custom--iframe"
        src={url}
        title={title}
        frameBorder="0"
      ></iframe>
    </div>
  );
};
