import React from "react";
import "./image-header.scss";
import { urlFor } from "../../utils/utils";

interface IProps {
  mainImage: any;
  text: string;
}

export const ImageHeader = ({ mainImage, text }: IProps) => {
  return (
    <div>
      <div className="image-header--container">
        <h2 className="image-header--text">
          <span>{text}</span>
        </h2>
        <div
          className="image-header--image"
          style={{
            backgroundImage: `url(${urlFor(mainImage)
              .width(1000)
              .format("png")
              // .format("jpg")
              // .format("webp")
              .url()})`,
          }}
        ></div>
      </div>
    </div>
  );
};
